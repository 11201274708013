@import 'ui-template';

@media screen and (min-width: 1700px) {
  html {
    background-color: #e9edf9;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    transform: translateZ(0);
    overflow: hidden;
  }

  body {
    overflow-y: scroll;
  }

  html,
  body,
  .app-header {
    width: 1700px;
    max-width: 1700px;
    margin: 0 auto;
  }

  .app-sidebar {
    border-bottom-left-radius: 10px;
  }
}

body {
  background: #fafbff;
}

h4 {
  font-weight: bold;
  font-size: 1rem;
}

.modal,
.modal-backdrop {
  z-index: 99999 !important;
}

.text {
  .hide {
    display: none;
  }

  .show {
    display: block;
  }
}

.no-results {
  display: flex;
  align-items: center;
  opacity: 0.6;
  font-weight: bold;

  img {
    margin-left: 8px;
    opacity: 0.6;
  }
}
